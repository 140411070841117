import React, { useContext } from 'react';
import './AttendancePrimaryTableStyle.scss';
import { CounterContext } from '../../Pages/Attendance/AttendanceModule';

const AttendancePrimaryTable = ({ Data, attendanceDate }) => {
    const { handleChangeCount, handleDivisionWiseValue,
        handleselecteDivisonDate, setattendanceDivSelectedLabel, handleTotalStudentsCount } = useContext(CounterContext);
    const currentStudents = Data;
    const handleViewDocuments = (documents) => {
        handleChangeCount(1);
        handleDivisionWiseValue(documents.divisionId);
        handleselecteDivisonDate(attendanceDate);
        setattendanceDivSelectedLabel(documents.divisionName);
        handleTotalStudentsCount(documents.totalStudents);
    };

    return (
        <div className="admission-primary-table-container">
            {
                Data.length > 0 ?
                    <table className="admission-primary-table">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Std.</th>
                                <th>Div.</th>
                                <th>Medium</th>
                                <th>Class Teacher</th>
                                <th>Total Student</th>
                                <th>Present Student</th>
                                <th>Absent Student</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentStudents.map((item, index) => (

                                    <tr key={index} >
                                        <td>{index + 1}</td>
                                        <td>{item.standardName}</td>
                                        <td>{item.divisionName}</td>
                                        <td>{item.mediumName}</td>
                                        <td>{item.classTeacher}</td>
                                        <td>{item.totalStudents}</td>
                                        <td>{item.presentStudents}</td>
                                        <td>{item.absentStudent}</td>
                                        <td>
                                            <a className={`attendance-primary-link-text`} onClick={() => handleViewDocuments(item)}>
                                                View
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    :
                    < div className="no_records_container">
                        <h3>No records found, Kindly reset your filter.</h3>
                    </div>
            }
        </div >
    );
};

export default AttendancePrimaryTable;