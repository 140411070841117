import React, { useState, useEffect, useContext } from 'react';
import './AttendanceSecondaryTableStyle.scss';
import { useNavigate } from 'react-router-dom';
import { CounterContext } from '../../Pages/Attendance/AttendanceModule';
import PrimaryButton from '../Common/PrimaryButton';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import { LuLoader2 } from "react-icons/lu";

const AttendanceSecondaryTable = ({
    studentsData,
    searchedData,
    studentCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    triggerGetDivisonWiseStudentsApi
}) => {
    const { handleChangeCount, divisionWiseId, selecteDivisonDate, setattendanceInstituteSelectedValue,
        setattendanceBoardSelectedValue, setattendanceMediumSelectedValue, setattendanceStandardSelectedValue,
        totatStudentsCount, handleStudentRollNo, handleStudentName, handleStudentuserID } = useContext(CounterContext);

    const [currentStudents, setCurrentStudents] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [editIdx, setEditIdx] = useState(null);
    const [tempComment, setTempComment] = useState('');
    const [SubmitListLoader, setSubmitListLoader] = useState(false);
    const navigate = useNavigate();
    const indexOfLastStudent = currentPage * pageSize;
    const indexOfFirstStudent = indexOfLastStudent - pageSize;
    const numberOfPages = Math.ceil(studentCount / pageSize);
    const [isAttendanceSubmitted, setisAttendanceSubmitted] = useState(false);


    useEffect(() => {

        if (studentsData && studentsData.length > 0) {
            const storedComments = JSON.parse(sessionStorage.getItem('studentComments')) || {};
            const storedAttendance = JSON.parse(sessionStorage.getItem('studentAttendance')) || {};

            const updatedStudents = studentsData.map(student => ({
                ...student,
                status: storedAttendance[student.userId] !== undefined
                    ? storedAttendance[student.userId]  // Use saved status if available
                    : student.status === -1 ? -1 : student.status === 1 ? -1 : 0,
                comment: storedComments[student.userId] || student.comment || ''  // Load saved comment from sessionStorage
            }));

            setCurrentStudents(updatedStudents);

            const hasZeroOrOne = studentsData.some(student => {
                if (student.status === 0 || student.status === 1) {
                    setisAttendanceSubmitted(true);
                }
            });
        }
    }, [studentsData]);

    useEffect(() => {
        const handleBackButtonClick = () => {
            console.log('Back button clicked. Clearing session storage...');
            sessionStorage.removeItem("studentAttendance");
            sessionStorage.removeItem("studentComments");
            // sessionStorage.clear();  // Clear all session storage or use sessionStorage.removeItem('attendance') to clear specific item
        };
        // Listen for back button event (popstate)
        window.onpopstate = handleBackButtonClick;
        window.addEventListener('beforeunload', handleBackButtonClick);

        // Cleanup the event listener on component unmount
        return () => {
            window.onpopstate = null;
            window.removeEventListener('beforeunload', handleBackButtonClick);
        };
    }, []);



    const toggleSelectAll = () => {
        const newSelectAllState = !selectAllChecked;
        setSelectAllChecked(newSelectAllState);
        const updatedStudents = currentStudents.map(student => ({
            ...student,
            status: newSelectAllState ? -1 : 0,
        }));
        setCurrentStudents(updatedStudents);
    };

    const handleCheckboxChange = (index) => {
        const updatedStudents = [...currentStudents];
        const newStatus = updatedStudents[index].status === -1 ? 0 : -1;
        updatedStudents[index].status = newStatus;
        setCurrentStudents(updatedStudents);
        const storedAttendance = JSON.parse(sessionStorage.getItem('studentAttendance')) || {};
        storedAttendance[updatedStudents[index].userId] = newStatus;
        sessionStorage.setItem('studentAttendance', JSON.stringify(storedAttendance));
    };

    const handleSecondaryTableViewClick = (item) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        handleChangeCount(2);
        handleStudentRollNo(item.rollNo);
        handleStudentName(item.name);
        handleStudentuserID(item.userId);
    };

    const handleBackButtonClick = () => {
        setattendanceInstituteSelectedValue('');
        setattendanceBoardSelectedValue('');
        setattendanceMediumSelectedValue('');
        setattendanceStandardSelectedValue('');
        setisAttendanceSubmitted(false);
        handleChangeCount(0);
        // sessionStorage.clear();  // Clear all session storage or use sessionStorage.removeItem('attendance') to clear specific item
        sessionStorage.removeItem('studentAttendance');
        sessionStorage.removeItem('studentComments');


    }



    const extractAttendanceDetails = () => {
        const storedAttendance = JSON.parse(sessionStorage.getItem('studentAttendance')) || {};
        const storedComments = JSON.parse(sessionStorage.getItem('studentComments')) || {};

        const currentAbsentStudents = currentStudents
            .filter(student => student.status === 0 || storedAttendance[student.userId] === 0)  // Also check if attendance in sessionStorage is 0
            .map(student => ({
                userId: student.userId,
                status: storedAttendance[student.userId] !== undefined ? storedAttendance[student.userId] : student.status,
                comment: storedComments[student.userId] || student.comment || "",
            }));

        currentAbsentStudents.forEach(student => {
            storedAttendance[student.userId] = student.status;
            storedComments[student.userId] = student.comment;
        });

        sessionStorage.setItem('studentAttendance', JSON.stringify(storedAttendance));
        sessionStorage.setItem('studentComments', JSON.stringify(storedComments));

        const res = Object.keys(storedAttendance).map(userId => ({
            userId,
            status: storedAttendance[userId],
            comment: storedComments[userId] || ""
        }));
        const res1 = res.filter(student => {
            return student.status == 0;
        })
        return res1;
    };

    const attendanceDetails = extractAttendanceDetails();


    const startEditing = (index) => {
        setEditIdx(index);
        setTempComment(currentStudents[index].comment || '');
    };

    const handleCommentChange = (e) => {
        setTempComment(e.target.value);
    };

    const saveEdit = () => {
        const updatedStudents = [...currentStudents];
        updatedStudents[editIdx].comment = tempComment;

        const storedComments = JSON.parse(sessionStorage.getItem('studentComments')) || {};
        storedComments[updatedStudents[editIdx].userId] = tempComment;
        sessionStorage.setItem('studentComments', JSON.stringify(storedComments));

        setCurrentStudents(updatedStudents);
        setEditIdx(null);
    };

    const cancelEdit = () => {
        setEditIdx(null);
    };

    const triggerSubmitButtonApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSubmitListLoader(true);
                let generatedApiUrl = `attendance/add`;
                let body = {
                    "userType": "student",
                    "divisionId": divisionWiseId,
                    "date": selecteDivisonDate,
                    "attendanceDetails": attendanceDetails
                }
                const response = await ApiCall(generatedApiUrl, body, 'post');

                setisAttendanceSubmitted(true);

                res();
                alert("Attendance Submitted Sucessfully!!")
                setSubmitListLoader(false);
            } catch (error) {
                setSubmitListLoader(false);
                console.error("Failed to trigger standard API", error);
                alert(error.errors)
            }
        });
    };

    const goToPage = (page) => {
        if (page >= 1 && page <= numberOfPages) {
            setCurrentPage(page);
            if (page == 1) {
                triggerGetDivisonWiseStudentsApi(false, searchedData, '1', pageSize);
            } else {
                triggerGetDivisonWiseStudentsApi(false, searchedData, ((pageSize * (page - 1)) + 1).toString(), pageSize);
            }
        }
    };

    const handlePageSizeChange = (e) => {
        let size = parseInt(e.target.value);
        if (!isNaN(size) && size >= 1) {
            if (size > 25) {
                size = 25;
                setPageSize(25);
            } else {
                setPageSize(size);
            }
            setCurrentPage(1); // Reset to the first page when page size changes
            if (window.dbPageSize) {
                clearTimeout(window.dbPageSize);
            }
            window.dbPageSize = setTimeout(() => {
                triggerGetDivisonWiseStudentsApi(false, searchedData, '1', size);
            }, 1500);
        }
    };

    const handleJumpToPageChange = (e) => {
        let page = parseInt(e.target.value);
        if (!isNaN(page)) {
            if (page > numberOfPages) {
                page = numberOfPages;
            }
            setCurrentPage(page);
            if (window.dbJumpPage) {
                clearTimeout(window.dbJumpPage);
            }
            window.dbJumpPage = setTimeout(() => {
                triggerGetDivisonWiseStudentsApi(false, searchedData, (page * pageSize) - pageSize + 1, pageSize);
            }, 1500);
        }
    }

    const generatePage = (i) => {
        return <div key={i} onClick={() => goToPage(i + 1)} className={currentPage === i + 1 ? 'current_page_active' : 'numbers_contianer'}>
            {i + 1}
        </div>
    }

    const renderPages = () => {
        const arr = [];

        if (numberOfPages <= 4) {
            for (let i = 0; i < numberOfPages; i++) {
                arr.push(generatePage(i));
            }
        } else {
            for (let i = currentPage - 2; i < currentPage + 1 && currentPage > 0 && currentPage < numberOfPages; i++) {
                arr[i] = generatePage(i);
            }
            for (let i = 0; i < numberOfPages; i++) {
                if ((i == 0 || i == 1 || i == numberOfPages - 2 || i == numberOfPages - 1) && !arr[i]) {
                    arr[i] = generatePage(i);
                } else if (i == 2 && !arr[i]) {
                    arr[i] = <p>...</p>;
                } else if (i == numberOfPages - 3 && !arr[i]) {
                    arr[i] = <p>...</p>;
                }
            }
        }
        return arr;
    }

    return (
        <>
            {
                studentsData.length == 0 ?
                    <div className="showing_index">
                        <p>No records found. Kindly reset your filters and try again.</p>
                    </div> :
                    <div className="admission-secondary-table-container">
                        <table className="admission-secondary-table">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>
                                        <input
                                            type="checkbox"
                                            className={`custom-checkbox ${selectAllChecked ? 'checked' : 'unchecked'}`}
                                            checked={selectAllChecked}
                                            onChange={toggleSelectAll}
                                            disabled={isAttendanceSubmitted}
                                            style={{ cursor: isAttendanceSubmitted ? 'not-allowed' : 'pointer' }} // Show different cursor if attendance is submitted

                                        />
                                    </th>
                                    <th>Roll No.</th>
                                    <th>Student Name</th>
                                    <th>Comment</th>
                                    <th colSpan={2}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentStudents.length > 0 ? (
                                    currentStudents.map((item, index) => (

                                        <tr key={index}>
                                            <td>{indexOfFirstStudent + index + 1}</td>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    className={`custom-checkbox ${item.status == -1 ? 'checked' : 'unchecked'}`}
                                                    checked={item.status == -1}
                                                    onChange={() => handleCheckboxChange(index)}
                                                    disabled={isAttendanceSubmitted}
                                                    style={{ cursor: isAttendanceSubmitted ? 'not-allowed' : 'pointer' }} // Show different cursor if attendance is submitted

                                                />
                                            </td>
                                            <td>{item.rollNo}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                {editIdx === index ? (
                                                    <>
                                                        <input
                                                            type="text"
                                                            value={tempComment}
                                                            onChange={handleCommentChange}
                                                            autoFocus
                                                            className={`temporary-comment-container ${isAttendanceSubmitted ? 'hidden' : ''}`}
                                                            disabled={isAttendanceSubmitted} // Disable input if attendance is submitted
                                                            readOnly={isAttendanceSubmitted} // Make input read-only if attendance is submitted
                                                        />
                                                        {!isAttendanceSubmitted && ( // Show save/cancel buttons only if attendance is not submitted
                                                            <div className='Comment-save-edit-container'>
                                                                <div>
                                                                    <a className="link-text" onClick={() => saveEdit()}>Save</a>
                                                                </div>
                                                                <div>
                                                                    <a className="link-text" onClick={() => cancelEdit()}>Cancel</a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <span
                                                        className={`limited-lines ${editIdx === index ? 'hidden' : ''}`}
                                                        onClick={() => !isAttendanceSubmitted && startEditing(index)} // Disable click if attendance is submitted
                                                        style={{ cursor: isAttendanceSubmitted ? 'not-allowed' : 'pointer' }} // Show different cursor if attendance is submitted
                                                    >
                                                        {item.comment || 'Click to edit'}
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <a className="attendance-secondary-link-text"
                                                    onClick={() => handleSecondaryTableViewClick(item)}
                                                >
                                                    View
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <div className="no_records_container">
                                        <h3>No records found, Kindly reset your filter.</h3>
                                    </div>
                                )}
                            </tbody>
                        </table>
                        <div className="Attendance_secondary-pagination">
                            <div className="Attendance_secondary-pagination_container">
                                <p>Showing {indexOfFirstStudent + 1} to {Math.min(indexOfFirstStudent + pageSize, totatStudentsCount)} of {totatStudentsCount} results</p>
                            </div>
                            <div className="Attendance_Secondary-pagination_controls">
                                <div className='Attendance_Secondary-display_container'>
                                    <p>Jump To Page</p>
                                    <input
                                        type="number"
                                        value={currentPage}
                                        onChange={handleJumpToPageChange}
                                        min="1"
                                        max={numberOfPages}
                                        placeholder="Page Size"
                                        className='Attendance_Secondary-extra_space'
                                    />
                                </div>
                                <div className='Attendance_Secondary-display_container'>
                                    <p>Display</p>
                                    <input
                                        type="number"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        min="1"
                                        max={"25"}
                                        placeholder="Page Size"
                                        className='Attendance_Secondary-extra_space'
                                    />
                                </div>
                                <div className="Attendance_Secondary-arrows_container">

                                    <div onClick={() => goToPage(currentPage - 1)} className={currentPage > 1 ? 'Attendance_Secondary-left_arrow_active extra_space' : 'Attendance_Secondary-left_arrow_btn_container Attendance_Secondary-extra_space'}>
                                        <p>{"<"}</p>
                                    </div>
                                    {/* <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>{'<'}</button> */}
                                    <div className='Attendance_Secondary-number_pages_parent'>
                                        {renderPages()}
                                    </div>
                                    <div className={currentPage != numberOfPages ? 'Attendance_Secondary-right_arrow_active' : 'Attendance_Secondary-right_arrow_btn_container'} onClick={() => goToPage(currentPage + 1)} >
                                        <p>{">"}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="secondary-screen-butttons-container">
                            <PrimaryButton
                                buttonText={"Back"}
                                onClick={() => handleBackButtonClick()}
                            />
                            <PrimaryButton
                                buttonText={"Submit"}
                                onClick={() => triggerSubmitButtonApi()}
                                disabled={isAttendanceSubmitted}
                                displayLoader={SubmitListLoader}
                            />
                        </div>
                    </div >
            }
        </>
    );
};

export default AttendanceSecondaryTable;
