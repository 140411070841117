
import React, { useState, useCallback } from 'react';
import './AttendanceModule_Style.scss';
import { useNavigate } from 'react-router-dom';
import PostLoginHeader from '../../Components/Common/PostLoginHeader';
import AttendancePrimaryScreen from '../../Components/AttendancePrimaryScreen/AttendancePrimaryScreen';
import AttendanceSecondaryScreen from '../../Components/AttendanceSecondaryScreen';
import StudentAttendanceRecord from '../../Components/StudentAttendanceRecord';

// Create the CounterContext outside the component
const CounterContext = React.createContext();

const AttendanceModule = () => {
    const [numberOfCount, setnumberOfCount] = useState(0);
    const [divisionWiseId, setdivisionWiseId] = useState(0);
    const [selecteDivisonDate, setSelecteDivisonDate] = useState('');

    const [attendanceInstituteselectedValue, setattendanceInstituteSelectedValue] = useState('');
    const [attendanceBoardSelectedValue, setattendanceBoardSelectedValue] = useState('');
    const [attendanceMediumSelectedValue, setattendanceMediumSelectedValue] = useState('');
    const [attendanceStandardSelectedValue, setattendanceStandardSelectedValue] = useState('');

    const [attendanceInstituteselectedLabel, setattendanceInstituteselectedLabel] = useState('');
    const [attendanceDivSelectedLabel, setattendanceDivSelectedLabel] = useState('');
    const [attendanceMediumSelectedLabel, setattendanceMediumSelectedLabel] = useState('');
    const [attendanceStandardSelectedLabel, setattendanceStandardSelectedLabel] = useState('');

    const [totatStudentsCount, settotatStudentsCount] = useState(0);
    const [studentName, setstudentName] = useState('');
    const [rollNo, setrollNo] = useState(0);
    const [userID, setuserID] = useState(0);


    const handleChangeCount = useCallback(
        (item) => {
            setnumberOfCount(item);
        },
        [setnumberOfCount],
    );

    const handleDivisionWiseValue = useCallback(
        (item) => {
            setdivisionWiseId(item);
        },
        [setdivisionWiseId],
    );

    const handleselecteDivisonDate = useCallback(
        (item) => {
            setSelecteDivisonDate(item);
        },
        [setSelecteDivisonDate],
    );

    const handleTotalStudentsCount = useCallback((item) => {
        settotatStudentsCount(item);
    }, [settotatStudentsCount]);

    const handleStudentName = useCallback(
        (item) => {
            setstudentName(item);
        },
        [setstudentName],
    );

    const handleStudentRollNo = useCallback(
        (item) => {
            setrollNo(item);
        },
        [setrollNo],
    );

    const handleStudentuserID = useCallback(
        (item) => {
            setuserID(item);
        },
        [setuserID],
    );



    const contextValue = {
        numberOfCount,
        handleChangeCount,
        divisionWiseId,
        handleDivisionWiseValue,
        selecteDivisonDate,
        handleselecteDivisonDate,
        attendanceInstituteselectedValue,
        setattendanceInstituteSelectedValue,
        attendanceBoardSelectedValue,
        setattendanceBoardSelectedValue,
        attendanceMediumSelectedValue,
        setattendanceMediumSelectedValue,
        attendanceStandardSelectedValue,
        setattendanceStandardSelectedValue,
        totatStudentsCount,
        handleTotalStudentsCount,
        studentName,
        rollNo,
        userID,
        handleStudentName,
        handleStudentRollNo,
        handleStudentuserID,
        attendanceStandardSelectedLabel, attendanceMediumSelectedLabel,
        attendanceDivSelectedLabel, attendanceInstituteselectedLabel,
        setattendanceStandardSelectedLabel, setattendanceMediumSelectedLabel,
        setattendanceDivSelectedLabel, setattendanceInstituteselectedLabel,
    };

    return (
        <CounterContext.Provider value={contextValue}>
            <div className={`attendance-main-container`}>
                {
                    numberOfCount === 0 ?
                        <>
                            <PostLoginHeader isLinearGradientVisible={true} />
                            <AttendancePrimaryScreen />
                        </>
                        :
                        numberOfCount === 1 ?
                            <>
                                <PostLoginHeader isLinearGradientVisible={true} />

                                <AttendanceSecondaryScreen />
                            </>
                            :
                            numberOfCount === 2 ?
                                <>
                                    <PostLoginHeader isLinearGradientVisible={false} />

                                    <StudentAttendanceRecord />
                                </>
                                : null
                }
            </div>
        </CounterContext.Provider>
    );
};

export { CounterContext };
export default AttendanceModule;
