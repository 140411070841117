import React, { useEffect, useState, useCallback, useContext } from 'react';
import './AttendancePrimaryScreen_Style.scss';
import { useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import '../../Utils//Styles/Colors.css';
import CommonDropDown from '../../Components/Common/CommonDropDown';
import PrimaryButton from '../../Components/Common/PrimaryButton';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import Calendar from 'react-calendar';
import { FaCalendar } from "react-icons/fa";
import CommonTextInput from '../../Components/Common/CommonTextInput/CommonTextInput';
import moment from 'moment/moment';
import AttendancePrimaryTable from '../../Components/AttendancePrimaryTable/AttendancePrimaryTable';
import { LuLoader2 } from "react-icons/lu";
import { CounterContext } from '../../Pages/Attendance/AttendanceModule';


const AttendancePrimaryScreen = (props) => {
    const {
        attendanceInstituteselectedValue, attendanceBoardSelectedValue, attendanceMediumSelectedValue, attendanceStandardSelectedValue,
        setattendanceInstituteSelectedValue, setattendanceBoardSelectedValue, setattendanceMediumSelectedValue, setattendanceStandardSelectedValue,
        setattendanceStandardSelectedLabel, setattendanceMediumSelectedLabel, setattendanceInstituteselectedLabel
    } = useContext(CounterContext);



    const navigate = useNavigate();
    const [sort, setSort] = useState('atoz');
    const [studentCount, setStudentCount] = useState(0);
    const [attendanceInstituteLoader, setAttendanceInstituteLoader] = useState(false);
    const [attendanceBoardLoader, setattendanceBoardLoader] = useState(false);
    const [mediumLoader, setMediumLoader] = useState(false);
    const [standardLoader, setStandardLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [studentListLoader, setStudentListLoader] = useState(false);
    const [attendancePrimaryTableData, setAttendancePrimaryTableData] = useState([
    ]);

    //Calendar
    const [AttendanceDate, setAttendanceDate] = useState(moment().format("DD/MM/YYYY") || "");

    const [showCalendar, setShowCalendar] = useState(false);

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const toggleCalendar = (date) => {
        setAttendancePrimaryTableData([]);

        setAttendanceDate(date instanceof Date ? formatDate(date) : date.target.value);
        setShowCalendar(!showCalendar);
    };

    const [attendanceinstitiuteOptions, setattendanceInstitiuteOptions] = useState([
        { value: '', label: 'Institute' },
    ]);

    const [attendanceBoardOptions, setattendanceBoardOptions] = useState([
        { value: '', label: 'Board' },
    ]);

    const [MediumOptions, setMediumOptions] = useState([
        { value: '', label: 'Medium' },
    ]);

    const [StandardOptions, setStandardOptions] = useState([
        { value: '', label: 'Standard' },
    ]);

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (event) => {
        setAttendanceDate(event.target.value || "");
    };


    useEffect(() => {
        document.title = 'Attendance';
        if (localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")) {
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling animation
            });
            navigate("/");
        }
        triggerattendanceInstituteApi();

    }, []);


    const triggerattendanceInstituteApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setAttendanceInstituteLoader(true);
                const response = await ApiCall("institute/getalluserinstitutes", null, 'get');
                const formattedOptions = response.responseJson.userInstitutesDetails.map(institute => ({
                    value: institute._id,
                    label: institute.institudeName
                }));
                setattendanceInstitiuteOptions([
                    { value: '', label: 'Institute' },
                    ...formattedOptions
                ]);
                setAttendanceInstituteLoader(false);
                res();
            } catch (error) {
                setAttendanceInstituteLoader(false);
                rej(error);
            }
        });
    }

    const triggerattendanceBoardApi = (instituteId) => {
        return new Promise(async (res, rej) => {
            try {
                setattendanceBoardLoader(true);
                const id = instituteId ? instituteId : attendanceInstituteselectedValue;
                let generatedApiUrl = "board/getallinstituteboards?instituteId=" + id;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                const formattedOptions = response.responseJson.instituteBoardsDetails.map(Board => ({
                    value: Board._id,
                    label: Board.boardName
                }));
                setattendanceBoardOptions([
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ]);
                setattendanceBoardLoader(false);
                res();
            } catch (error) {
                setattendanceBoardLoader(false);
                rej(error);
            }
        });
    }

    const triggerattendanceMediumApi = (boardId) => {
        return new Promise(async (res, rej) => {
            try {
                setMediumLoader(true);
                const id = boardId ? boardId : attendanceBoardSelectedValue;
                let generatedApiUrl = `medium/getallboardmediums?boardId=${id}`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                const formattedOptions = response.responseJson.boardMediumsDetails.map(medium => ({
                    value: medium._id,
                    label: medium.mediumName
                }));
                setMediumOptions([
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ]);
                setMediumLoader(false);
                res();
            } catch (error) {
                setMediumLoader(false);
                console.error("Failed to trigger medium API", error);
                rej(error);
            }
        });
    };

    const triggerattendanceStandardApi = (mediumId) => {
        return new Promise(async (res, rej) => {
            try {
                setStandardLoader(true);
                const id = mediumId ? mediumId : attendanceMediumSelectedValue;
                let generatedApiUrl = `standard/getallmediumstandards?mediumId=${id}`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                const formattedOptions = response.responseJson.mediumStandardsDetails.map(standard => ({
                    value: standard._id,
                    label: standard.standardName
                }));
                setStandardOptions([
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ]);
                setStandardLoader(false);
                res();
            } catch (error) {
                setStandardLoader(false);
                console.error("Failed to trigger standard API", error);
                rej(error);
            }
        });
    };

    const triggerGetDivisonWiseApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setStudentListLoader(true);
                let generatedApiUrl = `attendance/getdivisionswise?standardId=${attendanceStandardSelectedValue}&date=${AttendanceDate}`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                setAttendancePrimaryTableData(response.responseJson.divisionwiseattendancedetails);
                res();
                setStudentListLoader(false);
            } catch (error) {
                setStudentListLoader(false);
                alert(error.errors);
                console.error("Failed to trigger standard API", error);
                // rej(error.errors);
            }
        });
    };

    // const handleattendanceInstituteChange = useCallback(
    //     async (event) => {
    //         setattendanceInstituteSelectedValue(event.target.value);
    //         setattendanceInstituteselectedLabel(event.target.options[event.target.selectedIndex].text)
    //         try {
    //             await triggerattendanceBoardApi(event.target.value);
    //         } catch (error) {
    //             console.error("Failed to trigger attendance board API", error);
    //         }
    //     },
    //     [setattendanceInstituteSelectedValue, triggerattendanceBoardApi],
    // )

    const handleattendanceInstituteChange = useCallback(
        async (event) => {
            const selectedValue = event.target.value;
            const selectedLabel = event.target.options[event.target.selectedIndex].text;

            setattendanceInstituteSelectedValue(selectedValue);
            setattendanceInstituteselectedLabel(selectedLabel);

            if (selectedValue === "") { // Default value selected
                setattendanceBoardSelectedValue('');
                setattendanceMediumSelectedValue('');
                setattendanceStandardSelectedValue('');
            } else {
                try {
                    await triggerattendanceBoardApi(selectedValue);
                } catch (error) {
                    console.error("Failed to trigger attendance board API", error);
                }
            }
        },
        [setattendanceInstituteSelectedValue, setattendanceInstituteselectedLabel, triggerattendanceBoardApi]
    );

    const handleattendanceBoardChange = useCallback(
        async (event) => {
            const selectedValue = event.target.value;

            setattendanceBoardSelectedValue(selectedValue);

            if (selectedValue === "") { // Default value selected
                setattendanceMediumSelectedValue('');
                setattendanceStandardSelectedValue('');
            } else {
                try {
                    await triggerattendanceMediumApi(selectedValue);
                } catch (error) {
                    console.error("Failed to trigger attendance medium API", error);
                }
            }
        },
        [setattendanceBoardSelectedValue, triggerattendanceMediumApi]
    );

    const handleMediumChange = useCallback(
        async (event) => {
            const selectedValue = event.target.value;
            const selectedLabel = event.target.options[event.target.selectedIndex].text;

            setattendanceMediumSelectedValue(selectedValue);
            setattendanceMediumSelectedLabel(selectedLabel);

            if (selectedValue === "") { // Default value selected
                setattendanceStandardSelectedValue('');
            } else {
                try {
                    await triggerattendanceStandardApi(selectedValue);
                } catch (error) {
                    console.error("Failed to trigger attendance standard API", error);
                }
            }
        },
        [setattendanceMediumSelectedValue, setattendanceMediumSelectedLabel, triggerattendanceStandardApi]
    );

    const handleStandardChange = useCallback(
        (event) => {
            const selectedValue = event.target.value;
            const selectedLabel = event.target.options[event.target.selectedIndex].text;

            setattendanceStandardSelectedValue(selectedValue);
            setattendanceStandardSelectedLabel(selectedLabel);

            // No dependent dropdowns to reset
        },
        [setattendanceStandardSelectedValue, setattendanceStandardSelectedLabel]
    );


    const handleAppyClick = async () => {
        if (attendanceStandardSelectedValue == '') {
            alert("⚠️ Attention: You must select values for Institute Name, Board, Medium, and Standard!");
        } else {
            await triggerGetDivisonWiseApi();

        }
    }

    // const handleApplyClick = () => {
    //     setCurrentPage(1);
    //     setPageSize(10);
    //     // triggerGetStudentList();
    // }
    const CalendarModal = ({ value, onChange, onClose }) => {
        return (
            <>
                <div className="modal-overlay">
                    <div className="modal-content">
                        <span className="close" onClick={onClose}>&times;</span>
                        <Calendar
                            onChange={onChange}
                            value={value}

                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={`attendance-main-container`}>
            {/* <PostLoginHeader /> */}
            <div className="Attendance_Section_conatainer">
                <div className="Attendance_main_container">
                    {/* Pie Chart Section Starts */}
                    <div className="Attendance_Pie_Chart_Parent_Container">
                        <div className="Attendance_pie_Chart_Left_Container">
                            <div className="Attendance_Total_Students_container">
                                <h3 className='Attendance_Total_Students_title'>Total no. of Student :-</h3>
                                <h3 className='Attendance_Total_students_number'>000</h3>
                            </div>
                            <div className="Attendance_Pass_Out_Students_container">
                                <h3 className='Attendance_Pass_Out_Students_title'>Total Present Student :-</h3>
                                <h3 className='Attendance_present_students_number'>000</h3>
                            </div>
                            <div className="Attendance_Pass_Out_Students_container">
                                <h3 className='Attendance_Pass_Out_Students_title'>Total Absent Student :-</h3>
                                <h3 className='Attendance_absent_students_number'>000</h3>
                            </div>
                        </div>
                        <div className="Attendance_pie_Chart_Right_Container">

                            <div className="Attendance_Pie_Chart_Contianer">
                                <PieChart
                                    colors={['var(--paid_pie_chart-color)', 'var(--over-due-pie_chart-color)']}
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 55, label: 'Present Students' },
                                                { id: 1, value: 10, label: 'Absent Students', },
                                            ],
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                    margin={{ right: 200 }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Pie Chart Section ends */}

                    {/* filter's section Starts */}
                    <div className="Attendance_filter_section_parent_container">
                        <div className="Attendance_dashboard_institue_container">
                            <CommonDropDown
                                selectedValue={attendanceInstituteselectedValue}
                                handleSelectChange={handleattendanceInstituteChange}
                                options={attendanceinstitiuteOptions}
                                displayLoader={attendanceInstituteLoader}
                                extraStyles={{ width: "34vw", marginRight: "1vw" }}
                                applyCustomStyles={true}
                            />
                        </div>
                        <div className="">
                            <CommonDropDown
                                selectedValue={attendanceBoardSelectedValue}
                                handleSelectChange={handleattendanceBoardChange}
                                options={attendanceBoardOptions}
                                displayLoader={attendanceBoardLoader}
                                extraStyles={{ width: "9.5vw", marginRight: "1vw" }}
                                applyCustomStyles={true}

                            />
                        </div>
                        <div className="">
                            <CommonDropDown
                                selectedValue={attendanceMediumSelectedValue}
                                handleSelectChange={handleMediumChange}
                                options={MediumOptions}
                                displayLoader={mediumLoader}
                                extraStyles={{ width: "10vw", marginRight: "1vw" }}
                                applyCustomStyles={true}
                            />
                        </div>

                        <div className="">
                            <CommonDropDown
                                selectedValue={attendanceStandardSelectedValue}
                                handleSelectChange={handleStandardChange}
                                options={StandardOptions}
                                displayLoader={standardLoader}
                                extraStyles={{ width: "8vw", marginRight: "1vw" }}
                                applyCustomStyles={true}
                            />
                        </div>

                        <div className="attendance-calendar-container">
                            <div onClick={() => setShowCalendar(true)} className={`attendance-input-right-icon-container`}>
                                <FaCalendar onClick={toggleCalendar} size={"1.3vw"} />
                            </div>
                            <input
                                type="text"
                                className={`AttendanceStyles`}
                                value={AttendanceDate || ""}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                disabled={props.disabled}
                                maxLength={props.maxLength && props.maxLength}
                                onKeyDown={props.onKeyDown ? (e) => { return props.onKeyDown(e) } : () => { console.log("On key down") }}
                            />
                            {
                                showCalendar ?
                                    <CalendarModal
                                        value={''}
                                        onChange={(date) => {
                                            toggleCalendar(date);
                                        }}
                                        onClose={() => {
                                            setAttendanceDate(moment().format("DD/MM/YYYY"));
                                            setShowCalendar(false)
                                        }}
                                    />
                                    : null
                            }
                        </div>
                        <div className="attendance-reset-button-container">
                            <PrimaryButton
                                buttonText={"Reset"}
                                onClick={() => {
                                    setattendanceInstituteSelectedValue('');
                                    setattendanceBoardSelectedValue('');
                                    setattendanceMediumSelectedValue('');
                                    setattendanceStandardSelectedValue('');
                                    setSort('atoz');
                                    setCurrentPage(1);
                                    setPageSize(10);
                                    setAttendancePrimaryTableData([]);
                                    // triggerGetStudentList(true);
                                }}
                                className={"Attendance_Reset_button_style"}
                            />
                        </div>

                        <div className="apply_button_container">
                            <PrimaryButton
                                buttonText={"Apply"}
                                onClick={() => handleAppyClick()}
                                className={"Attendance_Reset_button_style"}
                            />
                        </div>
                    </div>
                    {/* filter's section ends */}

                    {/* Student table Section Starts */}
                    {
                        studentListLoader ?
                            <div className={`dashboard-student-list-loader`}>
                                <div>
                                    <LuLoader2 className={`spinner`} />
                                    <p>Fetching student records. Please wait...</p>
                                </div>
                            </div>
                            :
                            <AttendancePrimaryTable Data={attendancePrimaryTableData} currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} studentCount={studentCount} attendanceDate={AttendanceDate} />
                    }
                    {/* Student table Section ends */}
                </div>
            </div>
        </div >
    )
}

export default AttendancePrimaryScreen;