import React from "react";
import './App.css';
import './Utils/Styles/Common.scss';
import { Route, Routes } from 'react-router-dom';
import LandingPage from "./Pages/General/LandingPage";
import RegistrationPage from "./Pages/Registration/RegistrationPage";
import UserDashboard from "./Pages/Dashboard/UserDashboard";
import Admission from "./Pages/Admission";
import AdmissionTabsSection from "./Pages/AdmissionTabsSection";
import AdmissionForm from "./Pages/General/AdmissionForm";
import FeesReceipt from "./Pages/FeesReceipt";
import FeesReceiptInstallment from "./Pages/FeesReceiptInstallment";
import AttendanceModule from "./Pages/Attendance/AttendanceModule";
import SubscriptionPage from "./Pages/Subscription";


function App() {
  return (
    <div className="app">
      <Routes>
        {/* <Route path="/" element={<SubscriptionPage />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/dashboard" element={<UserDashboard />} />
        <Route path="/admission" element={<Admission />} />
        <Route path="/AdmissionTabsSection" element={<AdmissionTabsSection />} />
        <Route path="/admissionform" element={<AdmissionForm />} />
        <Route path="/feesReceipt" element={<FeesReceipt />} />
        <Route path="/feesReceiptInstallment" element={<FeesReceiptInstallment />} />
        <Route path="/attendanceModule" element={<AttendanceModule />} />

        {/* <Route path="/subscriptionPage" element={<SubscriptionPage />} /> */}
      </Routes>
    </div>
  )
}

export default App;
