import React, { useState, useEffect, } from 'react'
import '../AdmissionTabsSection/AdmissionTabsSection.scss'
import PreLoginHeader from '../../Components/Common/PreLoginHeader/PreLoginHeader';
import PostLoginHeader from '../../Components/Common/PostLoginHeader';
import StudentDetails from '../../Components/StudentsDetails';
import ParentDetails from '../../Components/ParentDetails/ParentDetails';
import PastAcademicReports from '../../Components/PastAcademicReports/PastAcademicReports';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, } from "react-icons/fa";
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import { FeesDetails } from '../../Components/FeesDetails/FeesDetails';
import PrimaryButton from '../../Components/Common/PrimaryButton';
import StudentFeesInfo from '../../Components/StudentFeesInfo';
import CommonDropDown from '../../Components/Common/CommonDropDown';
import { Socket } from '../../Utils/ApiComponets/Socket';
import { FaInfoCircle, } from "react-icons/fa";
import validation, { validDate } from '../../Utils/Validations/Validations';

const AdmissionTabsSection = () => {
    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);
    const [studentDetailsViewOnly, setStudentDetailsViewOnly] = useState(true);
    const [parentDetailsViewOnly, setParentDetailsViewOnly] = useState(true);
    const [pastAcademicRecordsViewOnly, setPastAcademicRecordsViewOnly] = useState(true);
    const [editing, setEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [profileImg, setProfileImg] = useState('');
    const [warningText, setWarningText] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [isProfileImgPresent, setIsProfileImgPresent] = useState(false);
    const [profileImgSize, setProfileImgSize] = useState(0);
    const [profileImgType, setProfileImgType] = useState('');
    const [progress, setProgress] = useState(0);
    const [wsStatus, setWsStatus] = useState('');
    const [profileImgFromServer, setProfileImgFromServer] = useState('')

    const handleTabClick = (index) => {
        setEditing(false);
        setStudentDetailsViewOnly(true);
        setParentDetailsViewOnly(true);
        setPastAcademicRecordsViewOnly(true);
        setErrors({});
        setApiError('');
        setDisableSubmit(false);
        setDisplaySubmitLoader(false);
        setActiveTab(index);
        if (index == 3) {
            //skip getstudentcall
        } else {
            triggerGetStudentDetailsApi(index);
        }
    };

    const tabs = [
        { id: 0, label: "Student Details" },
        { id: 1, label: "Parent Details" },
        { id: 2, label: "Academic Records" },
        { id: 3, label: "Fees Details" }
    ];

    //students form section

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');

    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [aadharNumber, setAadharNumber] = useState('');
    const [selectedGender, setSelectedGender] = useState('Male');
    const [selectedSection, setselectedSection] = useState('School');
    const [ogSelectedSection, setOgSelectedSection] = useState('School');
    const [mainButtonLoader, setMainButtonLoader] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showAdmissionCalendar, setshowAdmissionCalendar] = useState(false)
    const [admissionDate, setadmissionDate] = useState('');
    const [errors, setErrors] = useState({});
    const [showDeleteError, setShowDeleteError] = useState(false);
    const hideHeader = true;
    const viewOnly = true;

    useEffect(() => {
        document.title = 'Student Details';
        init();
    }, []);

    const init = async () => {
        try {
            await triggerSectionsApi();
            triggerGetStudentDetailsApi();
            await triggerGetUserProfileApi();
        } catch (e) {
            console.log(e);
        }
    }


    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const toggleAdmissionCalendar = () => {
        setshowAdmissionCalendar(!showAdmissionCalendar);
    };

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function formatAdmissionDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleCalendarChange = (date) => {
        setDob(date instanceof Date ? formatDate(date) : date.target.value);
        toggleCalendar();
    };


    const handleAdmissionCalendarChange = (date) => {
        let formatedadmissionDate = date instanceof Date ? formatAdmissionDate(date) : date.target.value
        setadmissionDate(formatedadmissionDate);
        toggleAdmissionCalendar();
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handlesectionChange = (event) => {
        setselectedSection(event.target.value);
    };


    const [instituteselectedValue, setInstituteSelectedValue] = useState('');
    const [streamSelectedValue, setStreamSelectedValue] = useState('');
    const [boardSelectedValue, setBoardSelectedValue] = useState('');
    const [mediumSelectedValue, setMediumSelectedValue] = useState('');
    const [standardSelectedValue, setStandardSelectedValue] = useState('');
    const [divisionSelectedValue, setDivisionSelectedValue] = useState('');
    const [academicYearSelectedValue, setAcademicYearSelectedValue] = useState('');

    const genderOptions = ['Male', 'Female', 'Other'];
    const [sectionOptions, setSectionOptions] = useState([]);
    const [sectionWithId, setSectionWithId] = useState([]);
    const [sdSectionLoader, setSdSectionLoader] = useState(false);
    const [sdStreamLoader, setSdStreamLoader] = useState(false);
    const [disableStream, setDisableStream] = useState(false);
    const [sdInstituteLoader, setSdInstituteLoader] = useState(false);
    const [sdBoardLoader, setSdBoardLoader] = useState(false);
    const [sdMediumLoader, setSdMediumLoader] = useState(false);
    const [sdStandardLoader, setSdStandardLoader] = useState(false);
    const [sdDivisionLoader, setSdDivisionLoader] = useState(false);
    const [parBoardLoader, setParBoardLoader] = useState(false);
    const [parMediumLoader, setParMediumLoader] = useState(false);
    const [parStandardLoader, setParStandardLoader] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);

    function vwToPx(vw) {
        const width = window.innerWidth;
        return (vw / 100) * width;
    }

    const normalizedRadius = vwToPx(2) - vwToPx(0.2); // radius minus border thickness
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;


    const triggerGetUserProfileApi = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const response = await ApiCall("user/getuserprofile?userId=" + studentId, null, 'get');
                console.log(response);
                setProfileImgFromServer(response.responseJson.imageUrl);
                res();
            } catch (e) {
                console.log(e);
                setProfileImg('');
                setProfileImgFromServer('');
                res();
            }
        })
    }

    const triggerSectionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSdSectionLoader(true);
                let userId = sessionStorage.getItem("userId");
                if (!userId) {
                    userId = localStorage.getItem("userId");
                }
                const response = await ApiCall("section/getallusersections?userId=" + userId, null, 'get');
                const formattedOptions = response.responseJson.userSectionDetails.map(section => section.sectionName);
                setSectionOptions([
                    ...formattedOptions
                ]);
                const formattedOptionsWithId = response.responseJson.userSectionDetails.map(section => ({
                    value: section._id,
                    label: section.sectionName
                }));
                setSectionWithId(formattedOptionsWithId);
                // console.log(formattedOptions)
                setSdSectionLoader(false);

                res();
            } catch (error) {
                setSdSectionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const [InstitiuteOptions, setInstitiuteOptions] = useState([
        { value: '', label: 'Institute' }
    ]);
    const [BoardOptions, setBoardOptions] = useState([
        { value: '', label: 'Board' }
    ]);
    const [MediumOptions, setMediumOptions] = useState([
        { value: '', label: 'Medium' }
    ]);
    const [StandardOptions, setStandardOptions] = useState([
        { value: '', label: 'Standard' }
    ]);
    const [DivisionOptions, setDivisionOptions] = useState([
        { value: '', label: 'Division' }
    ]);
    const [StreamOptions, setStreamOptions] = useState([
        { value: '', label: 'Stream' },
    ]);
    const [streamOptionsWithId, setStreamOptionsWithId] = useState([]);

    const [studentStreamFromApi, setStudentStreamFromApi] = useState('');


    const AcademicYearOptions = [
        { value: '', label: 'Academic Year' },
        { value: '2022-2023', label: '2022-2023' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2024-2025', label: '2024-2025' },
        { value: '2025-2026', label: '2025-2026' },
        { value: '2026-2027', label: '2026-2027' },
    ];

    const triggerBoardApi = (instituteId, streamId, unsetBoard) => {
        return new Promise(async (res, rej) => {
            try {
                setSdBoardLoader(true);
                const id = instituteId ? instituteId : instituteselectedValue;
                const selectStreamId = streamId ? streamId : streamSelectedValue;
                let generatedApiUrl = "board/getallinstituteboards?instituteId=" + id;
                if (selectedSection != 'School') {
                    let actualStreamId = streamOptionsWithId.find(val => val.label == selectStreamId)?.value;
                    actualStreamId = actualStreamId ? actualStreamId : selectStreamId;
                    generatedApiUrl += "&streamId=" + actualStreamId;
                }
                const response = await ApiCall(generatedApiUrl, null, 'get');
                const formattedOptions = response.responseJson.instituteBoardsDetails.map(Board => ({
                    value: Board._id,
                    label: Board.boardName
                }));
                const options = [
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ];
                setBoardOptions(options);
                const selectedBoardId = options.find(val => val.label == boardSelectedValue)?.value;
                selectedBoardId && !unsetBoard && setBoardSelectedValue(selectedBoardId);
                setSdBoardLoader(false);
                res(selectedBoardId);
            } catch (error) {
                setSdBoardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const handleStreamChange = async (event) => {
        setStreamSelectedValue(event.target.value);
        setBoardSelectedValue('');
        setBoardOptions([
            { value: '', label: 'Board' }
        ]);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                if (instituteselectedValue != '') {
                    await triggerBoardApi(null, event.target.value, true);
                }
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    }

    const handleBoardChange = (event) => {
        setBoardSelectedValue(event.target.value);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerMediumApi(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const triggerMediumApi = (boardId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdMediumLoader(true);
                const id = boardId ? boardId : boardSelectedValue;
                const response = await ApiCall("medium/getallboardmediums?boardId=" + id, null, 'get');
                const formattedOptions = response.responseJson.boardMediumsDetails.map(medium => ({
                    value: medium._id,
                    label: medium.mediumName // Assuming mediumName is the appropriate field for the label
                }));
                const options = [
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ];
                setMediumOptions(options);
                const mediumId = options.find(val => val.label == mediumSelectedValue)?.value;
                mediumId && setMediumSelectedValue(mediumId)
                setSdMediumLoader(false);
                res(mediumId);
            } catch (error) {
                setSdMediumLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };


    const handleMediumChange = (event) => {
        setMediumSelectedValue(event.target.value);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerStandardApi(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const triggerStandardApi = (mediumId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdStandardLoader(true);
                const id = mediumId ? mediumId : mediumSelectedValue;
                const response = await ApiCall("standard/getallmediumstandards?mediumId=" + id, null, 'get');
                const formattedOptions = response.responseJson.mediumStandardsDetails.map(standard => ({
                    value: standard._id,
                    label: standard.standardName // Assuming standardName is the appropriate field for the label
                }));
                const options = [
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ];
                setStandardOptions(options);
                const standardId = options.find(val => val.label == standardSelectedValue)?.value;
                standardId && setStandardSelectedValue(standardId);
                setSdStandardLoader(false);
                res(standardId);
            } catch (error) {
                setSdStandardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const handleStandardChange = (event) => {
        setStandardSelectedValue(event.target.value);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerDivisionApi(event.target.value);
                // triggerGetStandardFees(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const triggerDivisionApi = (standardId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdDivisionLoader(true);
                const id = standardId ? standardId : standardSelectedValue;
                const response = await ApiCall("division/getalldivisionstandards?standardId=" + id, null, 'get');
                const formattedOptions = response.responseJson.divisonStandardsDetails.map(division => ({
                    value: division.divisonId,
                    label: division.divisionName // Assuming divisionName is the appropriate field for the label
                }));
                const options = [
                    { value: '', label: 'Division' },
                    ...formattedOptions
                ];
                setDivisionOptions(options);
                const divisionId = options.find(val => val.label == divisionSelectedValue)?.value;
                divisionId && setDivisionSelectedValue(divisionId);
                setSdDivisionLoader(false);
                res(divisionId);
            } catch (error) {
                setSdDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const handleDivisionChange = (event) => {
        setDivisionSelectedValue(event.target.value);

    };

    // parents details form

    const [parentsfirstName, setparentsFirstName] = useState('');
    const [parentslastName, setparentsLastName] = useState('');
    const [parentsmobileNumber, setparentsMobileNumber] = useState('');
    const [parentsemail, setparentsEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const [appartmentName, setappartmentName] = useState('');
    const [streetName, setstreetName] = useState('');
    const [landmark, setlandmark] = useState('');
    const [cityName, setcityName] = useState('');
    const [officeName, setofficeName] = useState('');
    const [officeStreetName, setofficeStreetName] = useState('');
    const [officeLandmark, setofficeLandmark] = useState('');
    const [officeCityName, setofficeCityName] = useState('');
    const IndianStatesAndUTs = [
        { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
        { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
        { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
        { value: 'Assam', label: 'Assam' },
        { value: 'Bihar', label: 'Bihar' },
        { value: 'Chandigarh', label: 'Chandigarh' },
        { value: 'Chhattisgarh', label: 'Chhattisgarh' },
        { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Goa', label: 'Goa' },
        { value: 'Gujarat', label: 'Gujarat' },
        { value: 'Haryana', label: 'Haryana' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Karnataka', label: 'Karnataka' },
        { value: 'Kerala', label: 'Kerala' },
        { value: 'Ladakh', label: 'Ladakh' },
        { value: 'Lakshadweep', label: 'Lakshadweep' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Manipur', label: 'Manipur' },
        { value: 'Meghalaya', label: 'Meghalaya' },
        { value: 'Mizoram', label: 'Mizoram' },
        { value: 'Nagaland', label: 'Nagaland' },
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Puducherry', label: 'Puducherry' },
        { value: 'Punjab', label: 'Punjab' },
        { value: 'Rajasthan', label: 'Rajasthan' },
        { value: 'Sikkim', label: 'Sikkim' },
        { value: 'Tamil Nadu', label: 'Tamil Nadu' },
        { value: 'Telangana', label: 'Telangana' },
        { value: 'Tripura', label: 'Tripura' },
        { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
        { value: 'Uttarakhand', label: 'Uttarakhand' },
        { value: 'West Bengal', label: 'West Bengal' }
    ];
    const [State, setState] = useState('');
    const [officeState, setofficeState] = useState('');
    const [officePincode, setofficePincode] = useState('');
    const [pincode, setpincode] = useState('');
    const [AlternativeParentsMobileNumber, setAlternativeParentsMobileNumber] = useState('');

    const StateOptions = [
        { value: '', label: 'State' },
        { value: 'cbse', label: 'CBSE' },
        { value: 'icse', label: 'ICSE' },
        { value: 'state_board', label: 'State Board' },
    ];

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleOfficeStateChange = (event) => {
        setofficeState(event.target.value);
    };


    //post acadmeic records

    const [instituteName, setinstituteName] = useState('');
    const [medium, setMedium] = useState('');
    const [obtainedMarks, setObtainedMarks] = useState('');
    const [obtainedPercentage, setObtainedPercentage] = useState('');
    const [board, setBoard] = useState('');
    const [standard, setStandard] = useState('');
    const [outOfMarks, setOutOfMarks] = useState('');
    const [grade, setGrade] = useState('');

    const [mediumOptionspostacademic, setmediumOptionspostacademic] = useState([{ value: '', label: 'Medium' }]);


    const [boardOptionspostacademic, setBoardOptionspostacademic] = useState([{ value: '', label: 'Board' }]);
    const [standardOptionspostacademic, setStandardOptionspostacademic] = useState([{ value: '', label: 'Standard' }]);

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [displaySubmitLoader, setDisplaySubmitLoader] = useState(false);



    const handleMediumChangepostacademic = (event) => {
        setMedium(event.target.value);
    };

    const handleboardOptionspostacademicChange = (event) => {
        setBoard(event.target.value);
    };

    const handleStandardChangepostacademic = (event) => {
        setStandard(event.target.value);
    };

    useEffect(() => {
        if (isModalOpen || isDeleteModalOpen || showDeleteError) {
            document.getElementsByTagName('body')[0].classList.add("overflow-hide");
        } else {
            document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
        }
    }, [isModalOpen, isDeleteModalOpen, showDeleteError]);

    useEffect(() => {
        if (selectedSection == 'School') {
            setDisableStream(true);
            setStreamSelectedValue('');
        } else {
            setDisableStream(false);
            triggerStreamApi();
        }
        if (editing && ogSelectedSection != selectedSection) {
            setOgSelectedSection('-1');//above condition will never be satisfied by ogSelectedSection
            setSdStreamLoader(false);
            setStreamSelectedValue('');
            setStreamOptions([
                { value: '', label: 'Stream' }
            ]);
            // setInstitiuteOptions([
            //     { value: '', label: "Institute" },
            // ]);
            setInstituteSelectedValue('');
            setBoardSelectedValue('');
            setBoardOptions([
                { value: '', label: 'Board' }
            ]);
            setMediumSelectedValue('');
            setMediumOptions([
                { value: '', label: 'Medium' }
            ]);
            setStandardSelectedValue('');
            setStandardOptions([
                { value: '', label: 'Standard' }
            ]);
            setDivisionSelectedValue('');
            setDivisionOptions([
                { value: '', label: 'Division' }
            ]);
        }
    }, [selectedSection, editing]);

    const triggerStreamApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSdStreamLoader(true);
                const sectionId = sectionWithId.find((item) => item.label == selectedSection).value;
                // console.log(sectionId);
                const response = await ApiCall("stream/getallsectionstreams?sectionId=" + sectionId, null, 'get');
                const formattedOptions = response.responseJson.sectionStreamDetails.map(stream => ({
                    value: stream._id,
                    label: stream.streamName
                }));
                const options = [
                    { value: '', label: 'Stream' },
                    ...formattedOptions
                ];
                setStreamOptions(options);
                setStreamOptionsWithId(options);
                if (studentStreamFromApi == streamSelectedValue) {
                    const selectedStreamId = options.find(val => val.label == streamSelectedValue)?.value;
                    selectedStreamId && setStreamSelectedValue(selectedStreamId);
                }
                setSdStreamLoader(false);

                res();
            } catch (error) {
                setSdStreamLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerGetStudentDetailsApi = (index) => {
        return new Promise(async (res, rej) => {
            try {
                // setDivisionLoader(true);
                const studentId = sessionStorage.getItem("selectedStudent");
                const currentTab = index ? index : activeTab;
                let tab;
                if (currentTab == 0) {
                    tab = 'studentpersonaldetails';
                } else if (currentTab == 1) {
                    tab = 'studentparentdetails';
                } else if (currentTab == 2) {
                    tab = 'studentpastacademicdetails';
                }
                // else {
                //     tab = 'all';
                // }
                const response = await ApiCall("student/getstudent?studentId=" + studentId + "&filter=" + tab, null, 'get');
                let studentDetails, parentDetails, pastAcademicRecord;
                if (currentTab == 0) {
                    studentDetails = response.responseJson.studentDetails.studentPersonalDetails;
                    sessionStorage.setItem("standardId", studentDetails.standardId);
                    setFirstName(studentDetails.firstName);
                    setMiddleName(studentDetails.middileName);
                    setLastName(studentDetails.lastName);
                    setDob(studentDetails.dob);
                    setMobileNumber(studentDetails.mobileNumber?.toString());
                    setEmail(studentDetails.email);
                    let genderFetch;
                    if (studentDetails.gender == 'male') {
                        genderFetch = 'Male';
                    } else if (studentDetails.gender == 'female') {
                        genderFetch = 'Female';
                    } else {
                        genderFetch = 'Other';
                    }
                    setSelectedGender(genderFetch);
                    setselectedSection(studentDetails.section);
                    setOgSelectedSection(studentDetails.section);
                    // setselectedSection("School");
                    if (studentDetails.stream) {
                        setStudentStreamFromApi(studentDetails.stream);
                        setStreamOptions([
                            // { value: '', label: 'Stream' },
                            { value: studentDetails.stream, label: studentDetails.stream },
                        ])
                        setStreamSelectedValue(studentDetails.stream);
                    }
                    setInstitiuteOptions([
                        // { value: '', label: "Institute" },
                        { value: studentDetails.institute, label: studentDetails.institute }
                    ]);
                    setInstituteSelectedValue(studentDetails.institute);
                    setBoardOptions([
                        // { value: '', label: "Board" },
                        { value: studentDetails.board, label: studentDetails.board }
                    ]);
                    setBoardSelectedValue(studentDetails.board);
                    setMediumOptions([
                        // { value: '', label: "Medium" },
                        { value: studentDetails.medium, label: studentDetails.medium }
                    ]);
                    setMediumSelectedValue(studentDetails.medium);
                    setStandardOptions([
                        // { value: '', label: "Standard" },
                        { value: studentDetails.standard, label: studentDetails.standard }
                    ]);
                    setStandardSelectedValue(studentDetails.standard);
                    setDivisionOptions([
                        // { value: '', label: "Division" },
                        { value: studentDetails.division, label: studentDetails.division }
                    ]);
                    setDivisionSelectedValue(studentDetails.division);
                    setRollNo(studentDetails.rollNumber?.toString());
                    setAadharNumber(studentDetails.adharNumber);
                    setadmissionDate(studentDetails.admissionDate);
                } else if (currentTab == 1) {
                    parentDetails = response.responseJson.studentDetails.studentParentDetails;
                    setparentsFirstName(parentDetails.firstName);
                    setparentsLastName(parentDetails.lastName);
                    setparentsMobileNumber(parentDetails.mobileNumber?.toString());
                    setAlternativeParentsMobileNumber(parentDetails.alternetMobileNumber);
                    setparentsEmail(parentDetails.email);
                    setOccupation(parentDetails.occupation);

                    setappartmentName(parentDetails.residentialAddress.apartmentData);
                    setstreetName(parentDetails.residentialAddress.street);
                    setlandmark(parentDetails.residentialAddress.landMark);
                    setcityName(parentDetails.residentialAddress.city);
                    setState(parentDetails.residentialAddress.state);
                    setpincode(parentDetails.residentialAddress.pincode);

                    setofficeName(parentDetails.workAddress.apartmentData);
                    setofficeStreetName(parentDetails.workAddress.street);
                    setofficeLandmark(parentDetails.workAddress.landMark);
                    setofficeCityName(parentDetails.workAddress.city);
                    setofficeState(parentDetails.workAddress.state);
                    setofficePincode(parentDetails.workAddress.pincode);
                } else if (currentTab == 2) {
                    pastAcademicRecord = response.responseJson.studentDetails.studentPastAcademicDetails;
                    setinstituteName(pastAcademicRecord.instituteName);
                    if (pastAcademicRecord.board) {
                        setBoardOptionspostacademic([
                            // { value: '', label: 'Board' },
                            { value: pastAcademicRecord.board, label: pastAcademicRecord.board }
                        ]);
                        setBoard(pastAcademicRecord.board);
                    }
                    if (pastAcademicRecord.medium) {
                        setmediumOptionspostacademic([
                            // { value: '', label: 'Medium' },
                            { value: pastAcademicRecord.medium, label: pastAcademicRecord.medium }
                        ]);
                        setMedium(pastAcademicRecord.medium);
                    }
                    if (pastAcademicRecord.standard) {
                        setStandardOptionspostacademic([
                            // { value: '', label: 'Standard' },
                            { value: pastAcademicRecord.standard, label: pastAcademicRecord.standard }
                        ]);
                        setStandard(pastAcademicRecord.standard);
                    }
                    setObtainedMarks(pastAcademicRecord.obtainedMarks);
                    setOutOfMarks(pastAcademicRecord.outOfMarks);
                    if (pastAcademicRecord.obtainedMarks && pastAcademicRecord.outOfMarks && !isNaN(Number(pastAcademicRecord.obtainedMarks)) && !isNaN(Number(pastAcademicRecord.outOfMarks))) {
                        setObtainedPercentage(((pastAcademicRecord.obtainedMarks / pastAcademicRecord.outOfMarks) * 100).toFixed(2));
                    }
                    setGrade(pastAcademicRecord.overallGrade);
                }
                // setDivisionLoader(false);
                res();
            } catch (error) {
                console.log(error, "error");
                // setDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const handleAdmissionTabSubmitClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480)

    const [selectedTabMenu, setSelectedTabMenu] = useState("Student Details")
    const [tabMenuOptions, setTabMenuOptions] = useState([
        { value: 'Student Details', label: "Student Details" },
        { value: "Parent Details", label: "Parent Details" },
        { value: "Academic Records", label: "Academic Records" },
        { value: "Fees Details", label: "Fees Details" },

    ])

    const [apiError, setApiError] = useState('');

    const validateStudentDetails = () => {
        return new Promise((res, rej) => {
            try {
                let isValid = true;
                let errors = {};

                errors.firstName = validation.isValidFirstName(firstName);
                errors.lastName = validation.isValidLastName(lastName);
                errors.middleName = validation.isValidMiddleName(middleName);
                errors.mobileNumber = validation.isValidTabMobileNumber(mobileNumber);
                errors.rollNo = validation.isValidRollNumber(rollNo);
                errors.email = validation.isValidEmail(email);
                errors.dob = validation.isValidDob(dob);
                errors.selectedGender = validation.isGenderSelected(selectedGender);
                errors.selectedSection = validation.isSectionSelected(selectedSection);
                errors.institute = validation.isInstituteSelected(instituteselectedValue);
                errors.stream = validation.isStreamSelected(selectedSection, streamSelectedValue);
                errors.sdBoard = validation.isBoardSelected(boardSelectedValue);
                errors.sdMedium = validation.isMediumSelected(mediumSelectedValue);
                errors.sdStandard = validation.isStandardSelected(standardSelectedValue);
                errors.division = validation.isDivisionSelected(divisionSelectedValue);
                errors.admissionDate = validation.isValidAdmissionDate(admissionDate)



                for (const prop in errors) {
                    // console.log(prop);
                    if (errors[prop]) {
                        isValid = false;
                        break;
                    }
                }





                setErrors(errors);
                res(isValid);
            } catch (e) {
                rej(e);
            }
        })

    }

    const handleEditClick = async () => {
        if (editing) {
            setEditing(false);
            setErrors({});
            setApiError('');
            triggerGetStudentDetailsApi();
            if (activeTab == 0) {
                setStudentDetailsViewOnly(true);
                setIsProfileImageUploading(false);
                setIsProfileImgPresent(false);
                setProfileImg('');
                setProgress(0);
                setProfileImgSize(0);
                setProfileImgType('');
                triggerGetUserProfileApi();
            } else if (activeTab == 1) {
                setParentDetailsViewOnly(true);
            } else if (activeTab == 2) {
                setPastAcademicRecordsViewOnly(true);
            }
        } else {
            setEditing(true);
            if (activeTab == 0) {
                const instituteId = await triggerInstituteApi();
                const boardId = await triggerBoardApi(instituteId);
                const mediumId = await triggerMediumApi(boardId);
                const standardId = await triggerStandardApi(mediumId);
                await triggerDivisionApi(standardId);
                setStudentDetailsViewOnly(false);
            } else if (activeTab == 1) {
                setParentDetailsViewOnly(false);
            } else if (activeTab == 2) {
                triggerBoardOptionsApi();
                triggerPostAcademicMediumsApi();
                triggerStandardOptionsApi();
                setPastAcademicRecordsViewOnly(false);
            }
        }
    }

    const triggerPostAcademicMediumsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParMediumLoader(true);
                const response = await ApiCall("medium/getallmastermediums", null, 'get');
                const formattedOptions = response.responseJson.mediums.map(mediums => ({
                    value: mediums._id,
                    label: mediums.mediumName
                }));
                const options = [
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ];
                setmediumOptionspostacademic(options);
                const mediumId = options.find(val => val.label == medium)?.value;
                mediumId && setMedium(mediumId);
                setParMediumLoader(false);
                res();
            } catch (error) {
                setParMediumLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerBoardOptionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParBoardLoader(true);
                const response = await ApiCall("board/getallmasterboards", null, 'get');

                const formattedOptions = response.responseJson.boardsDetails.map(option => ({
                    value: option._id,
                    label: option.boardName
                }));
                const options = [
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ];
                setBoardOptionspostacademic(options);
                const boardId = options.find(val => val.label == board)?.value;
                boardId && setBoard(boardId);
                setParBoardLoader(false);
                res();
            } catch (error) {
                setParBoardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerStandardOptionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParStandardLoader(true);
                const response = await ApiCall("standard/getallmasterstandards", null, 'get');
                const formattedOptions = response.responseJson.standards.map(standards => ({
                    value: standards._id,
                    label: standards.standardName
                }));
                const options = [
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ];
                setStandardOptionspostacademic(options);
                const standardId = options.find(val => val.label == standard)?.value;
                standardId && setStandard(standardId);
                setParStandardLoader(false);
                res();
            } catch (error) {
                setParStandardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const handleInstituteChange = async (event) => {
        setInstituteSelectedValue(event.target.value);
        setBoardSelectedValue('');
        setBoardOptions([
            { value: '', label: 'Board' }
        ]);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                if (selectedSection == 'School' || (selectedSection == 'College' && streamSelectedValue != '')) {
                    await triggerBoardApi(event.target.value);
                }
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const triggerInstituteApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSdInstituteLoader(true);
                const response = await ApiCall("institute/getalluserinstitutes", null, 'get');
                const formattedOptions = response.responseJson.userInstitutesDetails.map(institute => ({
                    value: institute._id,
                    label: institute.institudeName
                }));
                const options = [
                    { value: '', label: 'Institute' },
                    ...formattedOptions
                ];
                setInstitiuteOptions(options);
                console.log(options);
                const selectedInstituteId = options.find(val => val.label == instituteselectedValue)?.value;
                // console.log(selectedInstituteId, 'issdtudeiddd', instituteselectedValue);
                selectedInstituteId && setInstituteSelectedValue(selectedInstituteId);
                // if(formattedOptions.length > 0) {
                //     setInstituteSelectedValue(formattedOptions[0].value);
                // }
                setSdInstituteLoader(false);

                res(selectedInstituteId);
            } catch (error) {
                setSdInstituteLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const validateParentDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                let isValid = true;
                let errors = {};

                errors.parentsfirstName = validation.isValidParentsFirstName(parentsfirstName);
                errors.parentslastName = validation.isValidParentsLastName(parentslastName);
                errors.parentsmobileNumber = validation.isValidParentsMobileNumber(parentsmobileNumber);
                errors.parentsemail = validation.isValidParentsEmail(parentsemail);
                errors.occupation = validation.isValidOccupationDetails(occupation);
                errors.appartmentName = validation.isValidAppartmentName(appartmentName);
                errors.streetName = validation.isValidStreetName(streetName);
                errors.landmark = validation.isValidLandmark(landmark);
                errors.cityName = validation.isValidCityName(cityName);
                errors.officeName = validation.isValidOfficeName(officeName);
                errors.officeStreetName = validation.isValidOfficeStreetName(officeStreetName);
                errors.officeLandmark = validation.isValidOfficeLandMark(officeLandmark);
                errors.officeCityName = validation.isValidOfficeCityName(officeCityName);
                errors.homeState = validation.isStateSelected(State);
                errors.officeState = validation.isOfficeStateSelected(officeState);
                errors.pincode = await validation.isValidPincode(pincode);
                errors.officePincode = await validation.isValidOfficePincode(officePincode);
                errors.AlternativeParentsMobileNumber = validation.isValidAlternateParentsMobileNumber(AlternativeParentsMobileNumber);


                for (const prop in errors) {
                    if (errors[prop]) {
                        isValid = false;
                        break;
                    }
                }

                setErrors(errors);
                res(isValid);
            } catch (e) {
                rej(e);
            }
        })
    }

    const validatePastAcademicRecords = () => {
        return new Promise((res, rej) => {
            try {
                let isValid = true;
                let errors = {};

                errors.obtainedMarks = validation.isValidObtainedMarks(obtainedMarks, outOfMarks);
                errors.outOfMarks = validation.isValidOutOfMarks(outOfMarks);
                errors.grade = validation.isValidGrade(grade);

                for (const prop in errors) {
                    if (errors[prop]) {
                        isValid = false;
                        break;
                    }
                }

                setErrors(errors);
                res(isValid);
            } catch (e) {
                rej(e);
            }
        })
    }

    useEffect(() => {
        if (obtainedMarks?.toString()?.trim() != '' && outOfMarks?.toString()?.trim() != '' && !isNaN(Number(obtainedMarks)) && !isNaN(Number(outOfMarks))) {
            if (Number(outOfMarks) == 0) {
                setObtainedPercentage('');
                return;
            }
            const percentage = (obtainedMarks / outOfMarks) * 100;
            setObtainedPercentage(percentage.toFixed(2));
        } else {
            setObtainedPercentage('');
        }
    }, [obtainedMarks, outOfMarks]);

    function displayWarning(text) {
        setWarningText(text);
        setShowWarningModal(true);
    }

    function hideWarning() {
        setWarningText('');
        setShowWarningModal(false);
    }

    function formatFileSizeToKb(fileSize) {
        const sizeInKB = fileSize / 1024; // Convert Bytes to KB
        return `${sizeInKB.toFixed(2)}kb`; // Keep two decimal places
    }

    const handleEditSubmitClick = async () => {
        try {
            if (disableSubmit) {
                return;
            }
            setApiError('');
            if (activeTab == 0) {
                const isValid = await validateStudentDetails();

                if (isValid) {
                    await updateStudentPersonalDetails();
                    if (profileImg) {
                        const userId = sessionStorage.getItem("selectedStudent");
                        setDisableSubmit(true);
                        setDisplaySubmitLoader(true);
                        const pureBase64 = profileImg.split(";base64,")[1];
                        const pureFileType = profileImgType.split("/")[1];
                        const pureFileSize = formatFileSizeToKb((profileImgSize));
                        const userAction = 'profile';
                        const userObjectId = userId;
                        const count = 100;
                        const checkSum = "d41d8cd98f00b204e9800998ecf8427e";//not used hence hardcoded
                        Socket(pureBase64, pureFileType, pureFileSize, userAction, userObjectId, count, checkSum, setProgress, setWsStatus);
                    }
                } else {
                    setApiError("Please solve above errors");
                }
            } else if (activeTab == 1) {
                const isValid = await validateParentDetails();
                if (isValid) {
                    await updateParentDetails();
                } else {
                    setApiError("Please solve above errors");
                }
            } else if (activeTab == 2) {
                const isValid = await validatePastAcademicRecords();
                if (isValid) {
                    updatePastAcademicRecords();
                } else {
                    setApiError("Please solve above errors");
                }
            }
        } catch (e) {
            console.log(e);
            setApiError(e?.errors[0] || "Something went wrong");
        }
    }

    useEffect(() => {
        if (wsStatus == 'uploading') {
            setIsProfileImageUploading(true);
        } else if (wsStatus == 'success') {
            //change to success
            setDisableSubmit(false);
            setDisplaySubmitLoader(false);
            setIsProfileImageUploading(false);
            setIsProfileImgPresent(false);
            setProfileImg('');
            setProgress(0);
            setProfileImgSize(0);
            setProfileImgType('');
            triggerGetUserProfileApi();
        } else if (wsStatus == 'failed') {
            //change to failed
            displayWarning("Error while uploading profile picture, please try again later");
            setDisableSubmit(false);
            setDisplaySubmitLoader(false);
            setIsProfileImageUploading(false);
            setIsProfileImgPresent(false);
            setProfileImg('');
            setProgress(0);
            setProfileImgSize(0);
            setProfileImgType('');
            triggerGetUserProfileApi();
        }
    }, [wsStatus]);

    const updatePastAcademicRecords = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const body = {
                    "studentPastAcademicDetails": {
                        "standardId": standard,
                        "boardId": board,
                        "mediumId": medium,
                        "instituteName": instituteName.trim(),
                        "obtainedMarks": obtainedMarks,
                        "outOfMarks": outOfMarks,
                        "grade": grade
                    }
                }
                setDisableSubmit(true);
                setDisplaySubmitLoader(true);
                const response = await ApiCall('student/updatestudentacademicdetails?userId=' + studentId, body, 'put');
                console.log(response);
                setDisableSubmit(false);
                setErrors({});
                setApiError('');
                setPastAcademicRecordsViewOnly(true);
                setDisplaySubmitLoader(false);
                setEditing(false);
            } catch (e) {
                setDisableSubmit(false);
                setDisplaySubmitLoader(false);
                rej(e);
            }
        })
    }

    const updateParentDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const body = {
                    "studentParentDetails": {
                        "firstName": parentsfirstName,
                        "lastName": parentslastName,
                        "occupation": occupation,
                        "countryCode": "+91",
                        "mobileNumber": parentsmobileNumber,
                        "isMobileNumberVerified": 0,
                        "alternetMobileNumber": AlternativeParentsMobileNumber,
                        "primaryEmail": parentsemail,
                        "isPrimaryEmailVerified": "",
                        "addressDetails": [
                            {
                                "addressType": "home",
                                "apartmentData": appartmentName,
                                "landMark": landmark,
                                "street": streetName,
                                "city": cityName,
                                "state": State,
                                "pincode": pincode,
                                "country": "India"
                            },
                            {
                                "addressType": "office",
                                "apartmentData": officeName,
                                "landMark": officeLandmark,
                                "street": officeStreetName,
                                "city": officeCityName,
                                "state": officeState,
                                "pincode": officePincode,
                                "country": "India"
                            }
                        ]
                    }
                }
                setDisableSubmit(true);
                setDisplaySubmitLoader(true);
                const response = await ApiCall('student/updatestudentparentdetails?userId=' + studentId, body, 'put');
                console.log(response);
                setDisableSubmit(false);
                setErrors({});
                setApiError('');
                setParentDetailsViewOnly(true);
                setDisplaySubmitLoader(false);
                setEditing(false);
            } catch (e) {
                setDisableSubmit(false);
                setDisplaySubmitLoader(false);
                rej(e);
            }
        })
    }

    const updateStudentPersonalDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const body = {
                    "studentPersonalData": {
                        "firstName": firstName,
                        "middleName": middleName,
                        "lastName": lastName,
                        "dob": dob,
                        "divisionId": divisionSelectedValue,
                        "sectionName": selectedSection,
                        "gender": selectedGender.charAt(0).toLowerCase() + selectedGender.slice(1),
                        "countryCode": "+91",
                        "mobileNumber": mobileNumber,
                        "emailAddress": email,
                        "rollNumber": rollNo.trim(),
                        "admissionDate": admissionDate
                    }
                }
                setDisableSubmit(true);
                setDisplaySubmitLoader(true);
                const response = await ApiCall('student/updatestudentpersonaldetails?userId=' + studentId, body, 'put');
                console.log(response);
                setDisableSubmit(false);
                setErrors({});
                setApiError('');
                setStudentDetailsViewOnly(true);
                setDisplaySubmitLoader(false);
                setEditing(false);
                res();
            } catch (e) {
                setDisableSubmit(false);
                setDisplaySubmitLoader(false);
                rej(e);
            }
        })
    }

    const deleteStudent = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                setDeleting(true);
                const response = await ApiCall("student/deletestudent?studentId=" + studentId, null, 'delete');
                let recentStudents = sessionStorage.getItem("recentlyAddedStudents");
                if (recentStudents) {
                    recentStudents = JSON.parse(recentStudents);
                    recentStudents = recentStudents.filter((val) => val.id != studentId);
                    sessionStorage.setItem("recentlyAddedStudents", JSON.stringify(recentStudents));
                }
                document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
                console.log(response);
                setDeleting(false);
                setIsDeleteModalOpen(false);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Smooth scrolling animation
                });
                navigate("/dashboard");
                res();
            } catch (e) {
                console.log(e);
                setDeleting(false);
                setIsDeleteModalOpen(false);
                setShowDeleteError(true);
                res();
            }
        })
    }

    return (
        <>
            {/* <PreLoginHeader setDisplayLoginPopup={setDisplayLoginPopup} /> */}

            <PostLoginHeader />

            <div className="Admission_tab_section_container">
                <div className={`back-to-dashboard-button-container`}>
                    <div
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth' // Smooth scrolling animation
                            });
                            navigate("/dashboard");
                        }}
                        className={`back-to-dashboard-button`}>
                        <FaChevronLeft />
                        <p>
                            Back to dashboard
                        </p>
                    </div>
                </div>
                <div className="Admission_tab_main_container">
                    <div className="tab-container">

                        <div className='dropdown_menu'>

                            < CommonDropDown
                                selectedValue={selectedTabMenu}
                                handleSelectChange={(event) => {
                                    setSelectedTabMenu(event.target.value)
                                    if (event.target.value == "Student Details") {
                                        setActiveTab(0)
                                    } else if (event.target.value == "Parent Details") {
                                        setActiveTab(1)
                                        handleTabClick(1);
                                    } else if (event.target.value == "Academic Records") {
                                        setActiveTab(2)
                                        handleTabClick(2);
                                    } else if (event.target.value == "Fees Details") {
                                        setActiveTab(3)
                                    }
                                }}
                                options={tabMenuOptions}
                            />
                        </div>
                        <div className="tabs">
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`tab ${activeTab === tab.id && 'active'}`}
                                    onClick={() => handleTabClick(tab.id)}
                                // style={{ borderRadius: tab.id == 3 ? 0 : "0.5vw" }}
                                >
                                    <div className={`tab_header_text ${activeTab === tab.id && 'active_text'}`}>
                                        {tab.label}
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className="tab-content " data-tab={activeTab} >
                            {
                                activeTab == 0 ?
                                    <div className={`details_page_button_container`}>
                                        <div className={`delete_button_container`}>
                                            <PrimaryButton
                                                buttonText={"Delete Student"}
                                                disabled={editing}
                                                onClick={() => setIsDeleteModalOpen(true)}
                                            />
                                        </div>
                                        <div className={`edit_button_container`}>
                                            <PrimaryButton
                                                buttonText={editing ? "Cancel" : "Edit"}
                                                onClick={handleEditClick}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                activeTab == 1 || activeTab == 2 ?
                                    <div className={`details_page_button_container`}>
                                        <div className={`edit_button_container`}>
                                            <PrimaryButton
                                                buttonText={editing ? "Cancel" : "Edit"}
                                                onClick={handleEditClick}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                            {activeTab === 0 &&
                                <StudentDetails
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    middleName={middleName}
                                    setMiddleName={setMiddleName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    mobileNumber={mobileNumber}
                                    sectionLoader={sdSectionLoader}
                                    instituteLoader={sdInstituteLoader}
                                    streamLoader={sdStreamLoader}
                                    boardLoader={sdBoardLoader}
                                    mediumLoader={sdMediumLoader}
                                    standardLoader={sdStandardLoader}
                                    divisionLoader={sdDivisionLoader}
                                    disableStream={disableStream}
                                    setMobileNumber={setMobileNumber}
                                    email={email}
                                    setEmail={setEmail}
                                    dob={dob}
                                    setDob={setDob}
                                    rollNo={rollNo}
                                    setRollNo={setRollNo}
                                    aadharNumber={aadharNumber}
                                    setAadharNumber={setAadharNumber}
                                    selectedGender={selectedGender}
                                    setSelectedGender={setSelectedGender}
                                    selectedSection={selectedSection}
                                    setselectedSection={setselectedSection}
                                    mainButtonLoader={mainButtonLoader}
                                    toggleCalendar={toggleCalendar}
                                    handleCalendarChange={handleCalendarChange}
                                    handleGenderChange={handleGenderChange}
                                    handlesectionChange={handlesectionChange}
                                    setErrors={setErrors}
                                    errors={errors}
                                    showCalendar={showCalendar}
                                    instituteselectedValue={instituteselectedValue}
                                    setInstituteSelectedValue={setInstituteSelectedValue}
                                    streamSelectedValue={streamSelectedValue}
                                    setStreamSelectedValue={setStreamSelectedValue}
                                    boardSelectedValue={boardSelectedValue}
                                    setBoardSelectedValue={setBoardSelectedValue}
                                    mediumSelectedValue={mediumSelectedValue}
                                    setMediumSelectedValue={setMediumSelectedValue}
                                    standardSelectedValue={standardSelectedValue}
                                    setStandardSelectedValue={setStandardSelectedValue}
                                    divisionSelectedValue={divisionSelectedValue}
                                    setDivisionSelectedValue={setDivisionSelectedValue}
                                    academicYearSelectedValue={academicYearSelectedValue}
                                    setAcademicYearSelectedValue={setAcademicYearSelectedValue}
                                    genderOptions={genderOptions}
                                    sectionOptions={sectionOptions}
                                    InstitiuteOptions={InstitiuteOptions}
                                    StreamOptions={StreamOptions}
                                    BoardOptions={BoardOptions}
                                    MediumOptions={MediumOptions}
                                    StandardOptions={StandardOptions}
                                    DivisionOptions={DivisionOptions}
                                    handleInstituteChange={handleInstituteChange}
                                    handleStreamChange={handleStreamChange}
                                    handleBoardChange={handleBoardChange}
                                    handleMediumChange={handleMediumChange}
                                    handleStandardChange={handleStandardChange}
                                    handleDivisionChange={handleDivisionChange}
                                    handleAdmissionCalendarChange={handleAdmissionCalendarChange}
                                    admissionDate={admissionDate}
                                    setadmissionDate={setadmissionDate}
                                    toggleAdmissionCalendar={toggleAdmissionCalendar}
                                    showAdmissionCalendar={showAdmissionCalendar}
                                    setshowAdmissionCalendar={setshowAdmissionCalendar}
                                    hideHeader={hideHeader}
                                    viewOnly={studentDetailsViewOnly}
                                    editing={editing}
                                    displayPhoto={true}
                                    profileImg={profileImg}
                                    setProfileImg={setProfileImg}
                                    isProfileImgPresent={isProfileImgPresent}
                                    setIsProfileImgPresent={setIsProfileImgPresent}
                                    profileImgSize={profileImgSize}
                                    setProfileImgSize={setProfileImgSize}
                                    profileImgType={profileImgType}
                                    setProfileImgType={setProfileImgType}
                                    displayWarning={displayWarning}
                                    isProfileImageUploading={isProfileImageUploading}
                                    setIsProfileImageUploading={setIsProfileImageUploading}
                                    normalizedRadius={normalizedRadius}
                                    circumference={circumference}
                                    strokeDashoffset={strokeDashoffset}
                                    progress={progress}
                                    profileImgFromServer={profileImgFromServer}
                                />
                            }
                            {activeTab === 1 &&
                                <ParentDetails
                                    parentsfirstName={parentsfirstName}
                                    setparentsFirstName={setparentsFirstName}
                                    parentslastName={parentslastName}
                                    setparentsLastName={setparentsLastName}
                                    parentsmobileNumber={parentsmobileNumber}
                                    setparentsMobileNumber={setparentsMobileNumber}
                                    parentsemail={parentsemail}
                                    setparentsEmail={setparentsEmail}
                                    occupation={occupation}
                                    setOccupation={setOccupation}
                                    appartmentName={appartmentName}
                                    setappartmentName={setappartmentName}
                                    streetName={streetName}
                                    setstreetName={setstreetName}
                                    landmark={landmark}
                                    setlandmark={setlandmark}
                                    cityName={cityName}
                                    setcityName={setcityName}
                                    officeName={officeName}
                                    setofficeName={setofficeName}
                                    officeStreetName={officeStreetName}
                                    setofficeStreetName={setofficeStreetName}
                                    officeLandmark={officeLandmark}
                                    setofficeLandmark={setofficeLandmark}
                                    officeCityName={officeCityName}
                                    setofficeCityName={setofficeCityName}
                                    State={State}
                                    setState={setState}
                                    officeState={officeState}
                                    setofficeState={setofficeState}
                                    officePincode={officePincode}
                                    setofficePincode={setofficePincode}
                                    pincode={pincode}
                                    setpincode={setpincode}
                                    errors={errors}
                                    setErrors={setErrors}
                                    mainButtonLoader={mainButtonLoader}
                                    setMainButtonLoader={setMainButtonLoader}
                                    StateOptions={IndianStatesAndUTs}
                                    handleStateChange={handleStateChange}
                                    handleOfficeStateChange={handleOfficeStateChange}
                                    AlternativeParentsMobileNumber={AlternativeParentsMobileNumber}
                                    setAlternativeParentsMobileNumber={setAlternativeParentsMobileNumber}
                                    hideHeader={hideHeader}
                                    viewOnly={parentDetailsViewOnly}
                                />
                            }
                            {activeTab === 2 &&
                                <PastAcademicReports
                                    instituteName={instituteName}
                                    setinstituteName={setinstituteName}
                                    medium={medium}
                                    setMedium={setMedium}
                                    obtainedMarks={obtainedMarks}
                                    setObtainedMarks={setObtainedMarks}
                                    obtainedPercentage={obtainedPercentage}
                                    setObtainedPercentage={setObtainedPercentage}
                                    board={board}
                                    setBoard={setBoard}
                                    standard={standard}
                                    boardLoader={parBoardLoader}
                                    mediumLoader={parMediumLoader}
                                    standardLoader={parStandardLoader}
                                    setStandard={setStandard}
                                    outOfMarks={outOfMarks}
                                    setOutOfMarks={setOutOfMarks}
                                    grade={grade}
                                    setGrade={setGrade}
                                    errors={errors}
                                    mainButtonLoader={mainButtonLoader}
                                    mediumOptions={mediumOptionspostacademic}
                                    boardOptions={boardOptionspostacademic}
                                    standardOptions={standardOptionspostacademic}
                                    handleMediumChange={handleMediumChangepostacademic}
                                    handleBoardChange={handleboardOptionspostacademicChange}
                                    handleStandardChange={handleStandardChangepostacademic}
                                    hideHeader={hideHeader}
                                    viewOnly={pastAcademicRecordsViewOnly}
                                />
                            }
                            {activeTab === 3 &&
                                <FeesDetails
                                />
                            }
                            {
                                apiError != '' ?
                                    <div className={`admission-form-error-container`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                        <div className={`login-form-error-container`}>
                                            <FaInfoCircle />
                                            <p>
                                                {apiError}
                                            </p>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                editing ?
                                    <div className="Primary_button">
                                        <PrimaryButton
                                            buttonText={"Submit"}
                                            className={"Admission_form_common_submit_button"}
                                            onClick={handleEditSubmitClick}
                                            displayLoader={displaySubmitLoader}
                                            disabled={disableSubmit}
                                        />
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div >
            {isModalOpen && (
                <div className="modal-overlaycontent_studentfees">
                    <div className="modal-content_studentfees">
                        {/* <span className="close" onClick={handleCloseModal}>&times;</span> */}
                        <StudentFeesInfo onClose={handleCloseModal} />
                    </div>
                </div>
            )}
            {isDeleteModalOpen && (
                <div className="modal-overlaycontent_studentfees delete_active">
                    <div className="modal-content_studentfees">
                        <div className={`delete_modal_main_container`}>
                            <div>
                                <p>
                                    Are you sure you want to delete <span>{firstName} {lastName}</span>?
                                </p>
                            </div>
                            <div className={`delete_modal_buttons_container`}>
                                <PrimaryButton
                                    buttonText="Yes"
                                    displayLoader={deleting}
                                    disabled={deleting}
                                    onClick={deleteStudent}
                                />
                                <PrimaryButton
                                    buttonText="No"
                                    disabled={deleting}
                                    onClick={() => setIsDeleteModalOpen(false)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {
                showDeleteError ?
                    <div className="modal-overlaycontent_studentfees delete_active">
                        <div className="modal-content_studentfees">
                            <div className={`delete_modal_main_container`}>
                                <div>
                                    <p>
                                        Something went wrong, please try again.
                                    </p>
                                </div>
                                <div className={`delete_error_modal_buttons_container`}>
                                    <PrimaryButton
                                        buttonText="Ok"
                                        disabled={deleting}
                                        onClick={() => setShowDeleteError(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                showWarningModal ?
                    <div className="modal-overlaycontent_studentfees warning-modal-main-container">
                        <div className="modal-content_studentfees warning-modal-inner-container">
                            <div className={`warning-modal-for-image`}>
                                <p>{warningText}</p>
                            </div>
                            <div className={`warning-modal-button-container`}>
                                <PrimaryButton
                                    buttonText="OK"
                                    onClick={() => {
                                        hideWarning()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default AdmissionTabsSection