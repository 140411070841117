import React, { useEffect, useState, useContext } from 'react';
import './AttendanceSecondaryScreen_Style.scss';
import { useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import '../../Utils//Styles/Colors.css';
import PrimaryButton from '../Common/PrimaryButton';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import Calendar from 'react-calendar';
import moment from 'moment/moment';
import AttendanceSecondaryTable from '../AttendanceSecondaryTable';
import { FaSearch } from 'react-icons/fa';
import { LuLoader2 } from "react-icons/lu";
import { CounterContext } from '../../Pages/Attendance/AttendanceModule';


const AttendanceSecondaryScreen = (props) => {
    const { handleChangeCount,
        divisionWiseId,
        selecteDivisonDate,
        attendanceStandardSelectedLabel, attendanceMediumSelectedLabel, attendanceDivSelectedLabel,
        attendanceInstituteselectedLabel, totatStudentsCount
    } = useContext(CounterContext);

    const navigate = useNavigate();

    const [studentCount, setStudentCount] = useState(totatStudentsCount);
    const [searchedData, setSearchedData] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [studentListLoader, setStudentListLoader] = useState(false);

    const formattedMediumLabel = attendanceMediumSelectedLabel.charAt(0).toUpperCase() + attendanceMediumSelectedLabel.slice(1)

    const filterData = [
        { label: attendanceInstituteselectedLabel, className: 'attendance-secondary-dashboard-institute-container' },
        { label: `${attendanceStandardSelectedLabel} Standard`, className: 'attendance-secondary-dashboard-standard-container' },
        { label: `${formattedMediumLabel} Medium`, className: 'attendance-secondary-dashboard-medium-container' },
        { label: `${attendanceDivSelectedLabel} Division`, className: 'attendance-secondary-dashboard-division-container' },
        { label: selecteDivisonDate, className: 'attendance-secondary-dashboard-division-container', isDate: true },
    ];

    const [studentsData, setStudentsData] = useState([]);

    const handleSearchChange = (event) => {
        setStudentListLoader(true);
        setSearchedData(event.target.value);
        const searchTerm = event.target.value;
        if (window.dbTimeout) {
            clearTimeout(window.dbTimeout);
        }
        window.dbTimeout = setTimeout(() => {
            triggerGetDivisonWiseStudentsApi(false, searchTerm);
        }, 1500);
        setCurrentPage(1);
        setPageSize(10);
    }

    const triggerGetDivisonWiseStudentsApi = (defaultCall, searchData, startNumber, recordsCount) => {
        return new Promise(async (res, rej) => {
            try {
                setStudentListLoader(true);
                const search = searchData ? searchData : 'all';
                const startNum = startNumber ? startNumber : '1';
                const count = recordsCount ? recordsCount : '10';
                let generatedApiUrl = `attendance/getdivisionswisestudents?divisionId=${divisionWiseId}&date=${selecteDivisonDate}&search=${search}&startNumber=${startNum}&recordsCount=${count}&sort=atoz`;
                const response = await ApiCall(generatedApiUrl, null, 'get');
                setStudentsData(response.responseJson.studentsAttendaceResponse);
                res();
                setStudentListLoader(false);
            } catch (error) {
                setStudentListLoader(false);
                console.error("Failed to trigger standard API", error);
                rej(error);
            }
        });
    };

    useEffect(() => {
        triggerGetDivisonWiseStudentsApi();
        document.title = 'Dashboard';
        if (localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")) {
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling animation
            });
            navigate("/");
        }
    }, []);


    return (
        <div className={`attendance-main-container`}>
            {/* <PostLoginHeader /> */}
            <div className="Attendance_Section_conatainer">
                <div className="Attendance_main_container">
                    {/* Pie Chart Section Starts */}
                    <div className="Attendance_Pie_Chart_Parent_Container">
                        <div className="Attendance_pie_Chart_Left_Container">
                            <div className="Attendance_Total_Students_container">
                                <h3 className='Attendance_Total_Students_title'>Total no. of Student :-</h3>
                                <h3 className='Attendance_Total_students_number'>000</h3>
                            </div>
                            <div className="Attendance_Pass_Out_Students_container">
                                <h3 className='Attendance_Pass_Out_Students_title'>Total Present Student :-</h3>
                                <h3 className='Attendance_present_students_number'>000</h3>
                            </div>
                            <div className="Attendance_Pass_Out_Students_container">
                                <h3 className='Attendance_Pass_Out_Students_title'>Total Absent Student :-</h3>
                                <h3 className='Attendance_absent_students_number'>000</h3>
                            </div>
                        </div>
                        <div className="Attendance_pie_Chart_Right_Container">

                            <div className="Attendance_Pie_Chart_Contianer">
                                <PieChart
                                    colors={['var(--paid_pie_chart-color)', 'var(--over-due-pie_chart-color)']}
                                    series={[
                                        {
                                            data: [
                                                { id: 0, value: 55, label: 'Present Students' },
                                                { id: 1, value: 10, label: 'Absent Students', },
                                            ],
                                        },
                                    ]}
                                    width={400}
                                    height={200}
                                    margin={{ right: 200 }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Pie Chart Section ends */}

                    {/* filter's section Starts */}
                    <div className="Attendance_filter_section_parent_container">
                        {filterData.map((item, index) => (
                            <div key={index} className={item.className}>
                                <h3>
                                    {item.label}
                                </h3>
                            </div>
                        ))}
                    </div>
                    {/* filter's section ends */}
                    {/* Search Bar Section Starts */}
                    <div className="Attendance_Search_section_parent_container">
                        <div className="Attendance_Search_main_container">
                            <div className="attendance-secondary-search_bar_container">
                                <div className="Attendance_search_icon">
                                    <FaSearch fontSize={"1.3vw"} />
                                </div>
                                <input type="text"
                                    placeholder="Search "
                                    className="Attendance_search_input"
                                    value={searchedData}
                                    onChange={handleSearchChange}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Search Bar Section ends */}
                    {/* Student table Section Starts */}
                    {
                        studentListLoader ?
                            <div className={`dashboard-student-list-loader`}>
                                <div>
                                    <LuLoader2 className={`spinner`} />
                                    <p>Fetching student records. Please wait...</p>
                                </div>
                            </div>
                            :
                            <AttendanceSecondaryTable studentsData={studentsData} currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} studentCount={studentCount} triggerGetDivisonWiseStudentsApi={triggerGetDivisonWiseStudentsApi} />
                    }
                    {/* Student table Section ends */}

                </div>
            </div>
        </div>
    )
}

export default AttendanceSecondaryScreen;